import styled from 'styled-components'

import Bg from '../../images/bg-representante.svg'
import Aspas from '../../images/aspas.svg'
import ArrowSelect from '../../images/arrow-select.svg'

import DetailsRepresentante from '../../images/details-representantes.svg'

export const BannerRepresentante = styled.section`
  width: 100%;
  background: url(${Bg}) no-repeat left top;
  padding-top: 72px;
  padding-bottom: 90px;
  border-bottom: 1px solid #cce8f8;
  .cont {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .texto {
    max-width: 490px;
    p {
      font-size: 16px;
      line-height: 26px;
      color: #ffffff;
      max-width: 467px;
      padding-left: 30px;
      margin-top: 31px;
    }
  }
  .video {
    width: 568px !important;
    height: 319px !important;
    border-radius: 8px;
    overflow: hidden;
    margin-left: 30px;
    margin-top: 130px;
    .react-player__preview {
      background-image: url(${`https://i.ytimg.com/vi/OuAGgGsIlpY/hqdefault.jpg`}) !important;
      &:hover {
        .react-player__shadow {
          transform: scale(1.05);
          transition: all 0.3s;
        }
      }
      .react-player__shadow {
        background: none !important;
        background-color: #ffffff !important;
        width: 61px !important;
        height: 61px !important;
        transition: all 0.3s;
        transform: scale(1);
        .react-player__play-icon {
          border-width: 10px 0px 10px 13px !important;
          border-color: transparent transparent transparent #0095da !important;
          box-shadow: none !important;
        }
      }
    }
  }
  @media (max-width: 1050px) {
    background-size: cover;
    padding: 40px 0px;
    height: auto;
    .cont {
      flex-direction: column;
      align-items: center;
    }
    .texto {
      margin: 0 auto;
      p {
        padding: 0;
        text-align: center;
        margin: 0 auto;
        margin-top: 40px;
      }
    }
    .video {
      margin: 0 auto;
      margin-top: 50px;
    }
  }
  @media (max-width: 480px) {
    .video {
      margin: 0 auto;
      margin-top: 50px;
      margin-top: 30px;
      width: 100% !important;
      height: 220px !important;
    }
    .texto {
      p {
        margin-top: 20px;
        font-size: 15px;
        line-height: 22px;
      }
    }
  }
`

export const FormContato = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;
    .icon {
      margin-right: 10px;
    }
    h3 {
      font-family: 'DepotNew';
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      color: #3c3d40;
    }
  }
`

export const FormContainer = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 13px 30px rgba(121, 155, 179, 0.22);
  border-radius: 8px 0px;
  width: 560px;
  height: 1267px;
  margin-top: 46px;
  margin-right: -50px;
  display: flex;
  flex-direction: column;
  padding: 41px 45px;
  z-index: 10;
  align-items: center;
  justify-content: center;
  &:before {
    content: '';
    background: url(${DetailsRepresentante}) no-repeat center center;
    width: 135px;
    height: 160px;
    position: absolute;
    bottom: 130px;
    left: -165px;
    z-index: 1;
  }
  .icone {
    position: absolute;
    bottom: -78px;
    left: 0;
  }
  .cont {
    position: relative;
    max-width: 438px;
    &:before {
      content: '';
      width: 71px;
      height: 61px;
      background: url(${Aspas}) no-repeat center center;
      position: absolute;
      top: -32px;
      left: -59px;
    }
  }
  p {
    font: normal 400 18px/28px 'DepotNew';
    color: #636466;
    margin-bottom: 35px;
  }
  h3 {
    font: normal bold 18px/26px 'DepotNew';
    color: #0095da;
  }
  .aviso {
    font-family: 'DepotNew';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #636466;
    align-self: flex-end;
    margin-bottom: 32px;
  }
  button {
    cursor: pointer;
    align-self: flex-end;
    font-family: 'DepotNew';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    width: 233px;
    height: 55px;
    background: #0ac4ac;
    border-radius: 8px 0px;
    transform: scale(1);
    transition: all 0.4s;
    &:hover {
      transform: scale(1.05);
      transition: all 0.4s;
    }
  }
  .sucesso-form {
    .resposta {
      max-width: 237px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      img {
        margin-bottom: 26px;
      }
      h3 {
        font: normal bold 28px/33px 'DepotNew' !important;
        color: #595959 !important;
        margin-bottom: 20px;
      }
      p {
        font: normal normal 14px/18px 'DepotNew' !important;
        text-align: center !important;
        color: #9f9f9f !important;
        margin-bottom: 0;
      }
    }
  }
  @media (max-width: 1050px) {
    margin-right: 0;
    width: 100%;
  }
  @media (max-width: 480px) {
    height: auto;
    .sucesso-form {
      padding: 40px 0;
    }
    margin-top: 30px;
    width: 100%;
    padding: 30px 20px;
    .title {
      justify-content: center;
      margin-bottom: 20px;
    }
    button {
      width: 100%;
      margin-top: 30px;
    }
    .MuiFormGroup-row {
      margin-bottom: 18px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 24px;
  label {
    font-family: 'DepotNew';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #94969b;
    margin-bottom: 10px;
  }
  span {
    font-size: 14px;
  }
  .MuiFormGroup-root {
    label {
      margin-bottom: 0px;
      > span:first-child {
        height: 42px;
        width: 42px;
        color: #0095da;
      }
    }
  }
  input,
  select,
  textarea {
    padding-left: 20px;
    width: 100%;
    height: 55px;
    background: #f5f6fa;
    border-radius: 8px 0px;
    font-family: 'DepotNew';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #636466;
    caret-color: #01cdf1;
  }
  select {
    cursor: pointer;
    appearance: none;
    padding-right: 40px;
    background: url(${ArrowSelect}) no-repeat #f5f6fa center right 20px;
  }
  textarea {
    resize: none;
    padding-top: 20px;
    height: 108px;
  }
  @media (max-width: 480px) {
    margin-bottom: 15px;
    label {
      font-size: 15px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
`
export const TwoInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    width: 218px;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    > div {
      width: 100%;
    }
  }
`

export const IconContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 36px;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    margin-right: 33px;
    border-radius: 42px;
    background-color: #e6f3fb;
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .title {
    font-family: 'DepotNew';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: #636466;
  }
  .sub {
    max-width: 310px;
    font-family: 'DepotNew';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    color: #636466;
  }
  &:first-child {
    align-items: center;
  }
`
