import React, { Component, useState } from 'react'
import Video from 'react-player'
import InputMask from 'react-input-mask'

// Components Globals
import Header from 'components/header'
import Navigation from 'components/navigation-pages'
import Footer from 'components/footer/main'
import SEO from 'components/seo'

import IbgeStateCity from 'components/forms/ibge-state-city'

import { getRdTrackId, getTrackingSource } from 'utils/cookies'

// Style this component
import { BannerRepresentante, FormContainer, FormContato, FormGroup, TwoInput } from '../../Style/representante'

import { Container, TitleBanner, SubtitleBanner, ButtonGreen } from '../../Style/global'

import IconContato from 'images/icon-chat.svg'
import SucessoContato from 'images/successo-form.svg'

// Sass Global
import 'sass/main.scss'

import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'

import { withStyles } from '@material-ui/core/styles'
import FormGroupM from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const GreenCheckbox = withStyles({
  root: {
    color: '#0095DA',
    '&$checked': {
      color: '#0095DA',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />)

const REPRESENTANTE_MUTATION = gql`
  mutation EnviarContatoRepresentante(
    $nomeCompleto: String!
    $email: String!
    $nomeEmpresa: String
    $cnpj: String!
    $cpf: String!
    $nomeResponsavel: String!
    $estado: String!
    $cidade: String!
    $telefone: String!
    $horarioContato: String
    $mensagem: String!
    $trabalhoAutomacaoComercial: Boolean!
    $conhecimentoSegmentoFarmaceutico: Boolean!
    $estruturaOuInvestimento: Boolean!
    $clientTrackingId: String
    $trafficSource: String
  ) {
    createRepresentante(
      input: {
        data: {
          nomeCompleto: $nomeCompleto
          email: $email
          nomeEmpresa: $nomeEmpresa
          cnpj: $cnpj
          cpf: $cpf
          nomeResponsavel: $nomeResponsavel
          estado: $estado
          cidade: $cidade
          telefone: $telefone
          horarioContato: $horarioContato
          mensagem: $mensagem
          trabalhoAutomacaoComercial: $trabalhoAutomacaoComercial
          conhecimentoSegmentoFarmaceutico: $conhecimentoSegmentoFarmaceutico
          estruturaOuInvestimento: $estruturaOuInvestimento
          clientTrackingId: $clientTrackingId
          trafficSource: $trafficSource
        }
      }
    ) {
      representante {
        createdAt
      }
    }
  }
`

const Representate = () => {
  // These state variables will hold the form values when it is filled out
  const [nomeCompletoValue, setNomeCompletoValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [nomeEmpresaValue, setNomeEmpresaValue] = useState('')
  const [CNPJValue, setCNPJValue] = useState('')
  const [CPFValue, setCPFValue] = useState('')
  const [nomeResponsavelValue, setNomeResponsavelValue] = useState('')
  const [estadoValue, setEstadoValue] = useState('')
  const [cidadeValue, setCidadeValue] = useState('')
  const [telefoneValue, setTelefoneValue] = useState('')
  const [horarioContatoValue, setHorarioContatoValue] = useState('')
  const [mensagemValue, setMensagemValue] = useState('')
  const [trabalhoAutomacaoComercialValue, setTrabalhoAutomacaoComercialValue] = useState('')
  const [conhecimentoSegmentoFarmaceuticoValue, setConhecimentoSegmentoFarmaceuticoValue] = useState('')
  const [estruturaOuInvestimentoValue, setEstruturaOuInvestimentoValue] = useState('')

  const handleStateChange = value => {
    setEstadoValue(value)
  }
  const handleCityChange = value => {
    setCidadeValue(value)
  }

  return (
    <FormContainer>
      <Mutation mutation={REPRESENTANTE_MUTATION}>
        {(createRepresentante, { loading, error, data }) => (
          <React.Fragment>
            {data ? (
              <div className="sucesso-form">
                <div className="resposta">
                  <img src={SucessoContato} alt="Icone de sucesso" />
                  <h3>Mensagem enviada com sucesso</h3>
                  <p>Responderemos ou entraremos em contato em no máximo 1 dia útil.</p>
                </div>
              </div>
            ) : (
              <FormContato
                id="form_representante"
                name="form_representante"
                onSubmit={async event => {
                  // when the form is submitted, we will run the signUp function passed down
                  // the Mutation component, and pass it the form values from the state variables
                  event.preventDefault()
                  createRepresentante({
                    variables: {
                      nomeCompleto: nomeCompletoValue,
                      email: emailValue,
                      nomeEmpresa: nomeEmpresaValue,
                      cnpj: CNPJValue,
                      cpf: CPFValue,
                      nomeResponsavel: nomeResponsavelValue,
                      estado: estadoValue,
                      cidade: cidadeValue,
                      telefone: telefoneValue,
                      horarioContato: horarioContatoValue,
                      mensagem: mensagemValue,
                      trabalhoAutomacaoComercial: trabalhoAutomacaoComercialValue === '' ? false : trabalhoAutomacaoComercialValue,
                      conhecimentoSegmentoFarmaceutico:
                        conhecimentoSegmentoFarmaceuticoValue === '' ? false : conhecimentoSegmentoFarmaceuticoValue,
                      estruturaOuInvestimento: estruturaOuInvestimentoValue === '' ? false : estruturaOuInvestimentoValue,
                      clientTrackingId: getRdTrackId(),
                      trafficSource: getTrackingSource(),
                    },
                  })
                }}
              >
                <div className="title">
                  <div className="icon">
                    <img src={IconContato} alt="Icone contato" />
                  </div>
                  <h3>Envie-nos uma mensagem</h3>
                </div>
                <div className="form-area">
                  <FormGroup>
                    <label htmlFor="nome_completo">Nome completo*</label>
                    <input
                      type="text"
                      name="nome_completo"
                      id="nome_completo"
                      required
                      value={nomeCompletoValue}
                      onChange={event => {
                        setNomeCompletoValue(event.target.value)
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="email">E-mail*</label>
                    <input
                      type="email"
                      name="email"
                      required
                      value={emailValue}
                      onChange={event => {
                        setEmailValue(event.target.value)
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="nome_empresa">Nome da empresa*</label>
                    <input
                      type="text"
                      name="nome_empresa"
                      id="nome_empresa"
                      required
                      value={nomeEmpresaValue}
                      onChange={event => {
                        setNomeEmpresaValue(event.target.value)
                      }}
                    />
                  </FormGroup>
                  <TwoInput>
                    <FormGroup>
                      <label htmlFor="cnpj">CNPJ*</label>
                      <InputMask
                        type="text"
                        name="cnpj"
                        id="cnpj"
                        required
                        mask="99.999.999/9999-99"
                        value={CNPJValue}
                        onChange={event => {
                          setCNPJValue(event.target.value)
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="cpf">CPF do responsável*</label>
                      <InputMask
                        type="text"
                        name="cpf"
                        id="cpf"
                        required
                        mask="999.999.999-99"
                        value={CPFValue}
                        onChange={event => {
                          setCPFValue(event.target.value)
                        }}
                      />
                    </FormGroup>
                  </TwoInput>
                  <FormGroup>
                    <label htmlFor="nome_responsavel">Nome do responsável*</label>
                    <input
                      type="text"
                      name="nome_responsavel"
                      id="nome_responsavel"
                      required
                      value={nomeResponsavelValue}
                      onChange={event => {
                        setNomeResponsavelValue(event.target.value)
                      }}
                    />
                  </FormGroup>
                  <TwoInput>
                    <IbgeStateCity onStateChange={handleStateChange} onCityChange={handleCityChange} />
                  </TwoInput>
                  <TwoInput>
                    <FormGroup>
                      <label htmlFor="telefone">Telefone*</label>
                      <InputMask
                        type="text"
                        name="telefone"
                        id="telefone"
                        required
                        mask="(99)99999-9999"
                        value={telefoneValue}
                        onChange={event => {
                          setTelefoneValue(event.target.value)
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="melhor_horario_contato">Melhor horário para contato*</label>
                      <input
                        type="text"
                        name="melhor_horario_contato"
                        id="melhor_horario_contato"
                        required
                        value={horarioContatoValue}
                        onChange={event => {
                          setHorarioContatoValue(event.target.value)
                        }}
                      />
                    </FormGroup>
                  </TwoInput>
                  <FormGroup>
                    <label htmlFor="mensagem_porque">Porquê quer ser um representante*</label>
                    <textarea
                      name="mensagem_porque"
                      id="mensagem_porque"
                      required
                      value={mensagemValue}
                      onChange={event => {
                        setMensagemValue(event.target.value)
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormGroupM row>
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            name="trabalhou_automatcao_comercial"
                            id="trabalhou_automatcao_comercial"
                            checked={trabalhoAutomacaoComercialValue ? true : false}
                            onChange={event => {
                              setTrabalhoAutomacaoComercialValue(event.target.checked)
                            }}
                          />
                        }
                        label="Trabalho com automação comercial"
                      />
                    </FormGroupM>

                    <FormGroupM row>
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            name="conhecimento_seguimento_farmaceutico"
                            id="conhecimento_seguimento_farmaceutico"
                            checked={conhecimentoSegmentoFarmaceuticoValue ? true : false}
                            onChange={event => {
                              setConhecimentoSegmentoFarmaceuticoValue(event.currentTarget.checked)
                            }}
                          />
                        }
                        label="Tenho conhecimento do segmento farmacêutico"
                      />
                    </FormGroupM>

                    <FormGroupM row>
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            name="estrutura_investimento"
                            id="estrutura_investimento"
                            checked={estruturaOuInvestimentoValue ? true : false}
                            onChange={event => {
                              setEstruturaOuInvestimentoValue(event.currentTarget.checked)
                            }}
                          />
                        }
                        label="Possuo uma estrutura comercial ou tenho interesse em investir"
                      />
                    </FormGroupM>
                  </FormGroup>
                </div>
                <ButtonGreen type="submit">Enviar</ButtonGreen>
              </FormContato>
            )}
          </React.Fragment>
        )}
      </Mutation>
    </FormContainer>
  )
}

class PageRepresentante extends Component {
  render() {
    return (
      <div className="main">
        <SEO title="Representantes" />

        <Header />

        <Navigation from={this.props.location.pathname} />

        <BannerRepresentante>
          <Container className="cont">
            <div>
              <div className="texto">
                <TitleBanner>representante</TitleBanner>
                <SubtitleBanner>O que é preciso para ser um representante?</SubtitleBanner>
                <p>
                  Para ser um representante INOVAFARMA, além de amar o que faz, é necessário possuir uma empresa de representação comercial
                  e uma equipe de vendas, suporte e implantador que entenda as necessidades e transmita bem ao cliente todos os diferenciais
                  do nosso produto. Ter amplo conhecimento do setor farmacêutico também é muito importante para o sucesso de um
                  representante INOVAFARMA.
                </p>
              </div>
              <Video className="video" url="https://youtu.be/OuAGgGsIlpY" light playing />
            </div>
            <Representate />
          </Container>
        </BannerRepresentante>

        <Footer padding_maior={false} />
      </div>
    )
  }
}

export default PageRepresentante
